@font-face {
  font-family: bl-bold;
  src: url(../fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff);
}

@font-face {
  font-family: bl-medium;
  src: url(../fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.woff);
}

@font-face {
  font-family: bl-thin;
  src: url(../fonts/Geomanist-Thin-Webfont/geomanist-thin-webfont.woff);
}

@font-face {
  font-family: bl-regular;
  src: url(../fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff);
}

@font-face {
  font-family: bl-light;
  src: url(../fonts/Geomanist-Light-Webfont/geomanist-light-webfont.woff);
}

@font-face {
  font-family: bl-extralight;
  src: url(../fonts/Geomanist-ExtraLight-Webfont/geomanist-extralight-webfont.woff);
}

@font-face {
  font-family: bl-black;
  src: url(../fonts/Geomanist-Black-Webfont/geomanist-black-webfont.woff);
}

body {
  background-color: white;
}

img {
  mix-blend-mode: darken;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "bl-bold";
  color: #333333;
}

p.label,
address,
span,
div {
  font-family: "bl-regular";
}

button {
  font-family: "bl-medium";
}

a {
  font-family: "bl-regular";
  text-decoration: none;
  color: #333333;
}

a:hover {
  color: black;
}

li {
  list-style: none;
  text-align: left;
}


.inner-header {
  width: 80%;
}

.navbar > .container {
  margin-top: 0px;
}

/* Custom CSS */
.navbar-toggler {
  border: none; 
  box-shadow: none; 
}

.navbar-toggler:focus {
  box-shadow: none;
}

.scroll-card:first-child {
  margin-left: 0 !important;
}

.article-card:first-child {
  margin-left: 0 !important;
}

.sub-container {
  width: 88%;
  margin-left: 6%;
  border-radius: 15px;
  margin-top: -46px;
  padding: 50px;
  border: none;
}

.first-card {
  padding: 20px;
  background: #ffffff;
  border: none;
  border-radius: 0px !important;
}

.second-card {
  padding: 20px;
  background: #ffffff;
  border: none;
  border-radius: 0 !important;
}

.carousel0 {
  background: linear-gradient(114.87deg, #ff4d4d 11.43%, #ff9d33 71.99%);
  border-radius: 8px;
}

.carousel1 {
  background: linear-gradient(20.7deg, #6706b4 58.16%, #cf4b00 158.12%);
  border-radius: 8px;
}

.upto-off {
  padding: 0px;
  color: #ffffff;
  border-radius: 8px;
}

.upto-off span {
  font-weight: 400;
  font-size: 40px;
}

.upto-off p {
  font-weight: 500;
  font-size: 24px;

  color: #ffffff;
}

.upto-off img {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  overflow: hidden;
}

.left-carousel-img {
  float: right;
  margin-top: 30px;
  width: 300px;
}

.home-card-img-first {
  width: 118px;
  height: 118px;
  background: #fffce4;
  cursor: pointer;
}

.sub-cat-img {
  width: 118px;
  height: 116px;
  padding: 0px 10px;
  border-radius: 13.1244px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
}

.home-card-img {
  width: auto;
  height: 6rem;
  background: #fffce4;
  padding: 20px;
  border-radius: 13.1244px;
  cursor: pointer;
}

.home-card-text {
  margin-top: 0.5rem;
  width: 6rem;
}

.home-card-img1 {
  width: 118px;
  height: 116px;
  background: #f1f9ff;
  border-radius: 13.1244px;
  box-shadow: 0px 4px 16px rgba(66, 66, 66, 0.1);
  padding: 20px;
  cursor: pointer;
}

.home-card-img2 {
  width: 118px;
  height: 116px;
  background: #e5fff4;
  border-radius: 13.1244px;
  box-shadow: 0px 4px 16px rgba(66, 66, 66, 0.1);
  padding: 20px;
  cursor: pointer;
}

.home-card-img3 {
  width: 130px;
  height: 116px;
  background: #ffeacc;
  border-radius: 13.1244px;
  box-shadow: 0px 4px 16px rgba(66, 66, 66, 0.1);
  padding: 20px;
  cursor: pointer;
}

.home-card-img4 {
  width: 118px;
  height: 116px;
  background: #ffe7fd;
  border-radius: 13.1244px;
  box-shadow: 0px 4px 16px rgba(66, 66, 66, 0.1);
  padding: 20px;
  cursor: pointer;
}

.home-card-img5 {
  background: #ffeded;
  border-radius: 13.1244px;
  box-shadow: 0px 4px 16px rgba(66, 66, 66, 0.1);
  width: 118px;
  height: 116px;
  padding: 20px;
  cursor: pointer;
}

.home-cat {
  margin-top: 20px;
}

.second-card-heading {
  width: 100%;
  display: flex;
}

.second-card-inner {
  flex: 3;
}

.second-card-inner label {
  float: right;
}

.second-card-inner {
  flex: 1;
}

.home-card-img-second {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.second-img-inner {
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
}

.baby-massage-btn {
  display: flex;
  width: 100%;
  text-align: center;
  margin: 10px;
}

.app-button {
  background: transparent;
  border: none;
  border-radius: 50px;
  align-items: center;
  padding: 8px 12px;
  width: 170px;
  color: #333333;
  margin: 10px;
  font-family: "bl-medium";
  cursor: alias;
}

.app-button:first-child {
  margin-left: -10px;
}

.app-button:hover {
  background-color: transparent;
  color: #333;
  border: none;
}

.row {
  text-align: center;
  border: none;
}

.scroll-row {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin-top: 20px;
}

.scroll-row::-webkit-scrollbar {
  display: none;
}

label {
  font-weight: 500;
  color: #666666;
}

.see-all {
  color: #ed4d41;
}

.badge {
  background-color: #f0f0f0 !important;
  border: 1px solid #dfdfdf;
  color: #333333;
  border-radius: 25px;
  padding: 0.5rem;
}

.scroll-card {
  max-width: 33.333%;
  border: 0;
  flex-basis: 21.333%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 20px;
}

.scroll-card img {
  width: 100%;
  border-radius: 10px;
}

.card > img {
  margin-bottom: 0.75rem;
  width: 100%;
}

.card-text {
  font-size: 85%;
  margin-top: 10px;
}

#category-card-group {
  width: -webkit-fill-available !important;
}

.product-card {
  margin-bottom: 2rem;
  flex-grow: 0;
  background: #ffffff;
  border-radius: 12px;
  margin: 30px;
  width: 290px;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  overflow: hidden;
}

button.app-button.btn.btn-primary {
  width: auto !important;
}

.product-img {
  width: 290px;
  border-radius: 19px;
}

.article-card {
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 33.333%;
  flex-grow: 0;
  background: #ffffff;
  border-radius: 12px;
  margin: 10px;
  width: 189px;
}

.article-card-sub-category {
  border: 0;
  background: #ffffff;
  margin: 20px;
  width: 100px;
  text-align: center;
}

.article-card-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2rem;
  letter-spacing: 0.04em;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-card-text {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #333333;
  margin-left: 10px;
  margin-top: -10px !important;
}

.article-p-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2rem;
  letter-spacing: 0.04em;
  color: #333333;
  cursor: pointer;
  font-family: "bl-medium";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

small {
  cursor: pointer;
}

.product-p-heading {
  letter-spacing: 0.04em;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #333333;
  font-weight: 500;
}

.me-auto {
  float: left;
  margin-left: 70%;
}

.brand-page-brand-img {
  width: 96px;
  height: 96px;
  box-shadow: 0px 3px 12px rgba(47, 47, 47, 0.1);
  border-radius: 24px;
}

.sub-text-main {
  margin-left: 32px;
  float: left;
  margin-top: 35px;
}

.btnMessage {
  width: 171px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #333333;
}

.btnFollow {
  width: 171px;
  height: 44px;
  background: #ed4d41;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  margin-left: 10px;
}

.btn-div {
  float: right;
  width: 40%;
  height: 200px;
}

.btn-div button {
  width: 48%;
  float: left;
  height: 44px;
  background: #ed4d41;
  border-radius: 8px;
  border: none;
  color: white;
  margin-top: 70px;
}

.temp button {
  margin-left: 10px;
  height: 44px;
  background: #ed4d41;
  border-radius: 8px;
  border: none;
  color: white;
  margin-top: 0px;
  width: 150px;
}

.temp button:first-child {
  background-color: #ffffff;
  border: solid black 1px;
  margin-left: 0px;
  color: black;
}

.btn-div button:first-child {
  background-color: #ffffff;
  border: solid black 1px;
  margin-right: 2%;
  color: black;
}

.bp-left-section {
  float: left;
  width: 100%;
  height: 200px;
}

.sub-follower {
  display: flex;
  margin-top: 22px;
}

#sub-follower-inner {
  font-size: 18px;
  line-height: 18px;
}

#sub-follower-inner span {
  color: #666666;
  font-size: 14px;
}

#sub-follower-post span {
  color: #666666;
  font-size: 14px;
}

#sub-follower-post {
  font-size: 18px;
  line-height: 18px;
  margin-left: 20px;
}

.card-nav-brand {
  display: flex;
}

.flex-container {
  transform: translateY(-2rem);
  display: flex;
  border-top: solid #f1f1f1 1px;
}

.flex-container p {
  width: 150px;
  text-align: center;
  line-height: 4rem;
  margin-bottom: 0px;
  cursor: pointer;
}

.flex-container p:first-child {
  border-bottom: solid black 3px;
  border-radius: 2px;
}

.cp-card {
  background: #ffffff;
  border-radius: 12px;
  height: auto;
  border: none;
  font-family: "bl-regular";
  width: 100%;
}

.brand-right-card {
  padding: 20px;
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 8px rgba(66, 66, 66, 0.1);
  margin-bottom: 2rem;
  border-radius: 15px !important;
}

.cp-third-page-head label {
  font-family: "bl-bold";
}

.cp-third-page-head p {
  font-family: "bl-regular";
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  width: 100%;
  letter-spacing: 0.04em;
  color: #666666;
}

.see-all {
  float: right;
  font-family: "bl-bold";
  color: #ed4d41;
}

.brand-left-card {
  float: right;
  width: 30%;
  height: auto;
}

.products-see-all {
  width: 100%;
  float: left;
  color: #ed4d41;
  font-family: "bl-bold";
  margin-left: 13px;
}

.product-div {
  width: 100%;
  float: left;
  border-bottom: solid #f1f1f1 1px;
  margin-top: 30px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.product-div:last-child {
  border-bottom: solid #f1f1f1 0px;
}

.product-div-left {
  width: 96px;
  height: 96px;
  padding: 12px 22px;
  float: left;
  height: 100px;
  background: rgba(255, 185, 185, 0.25);
  border-radius: 50%;
}

.product-div-right {
  padding: 5px;
  float: left;
  margin-top: 20px;
}

.product-div-right p {
  line-height: 1rem;
  font-size: 14px;
  color: #717171;
}

.product-div-right p:first-child {
  font-weight: 900;
  color: #383838;
}

.inner-right {
  background-color: white;
  border-radius: 10px;
  width: 100%;
}

.product-div-right button {
  padding: 7px 20px 7px 20px;
  background-color: transparent;
  border: none;
  border: solid black 1px;
  font-size: 14px;
  border-radius: 10px;
  color: #000000;
}

.brand-photos {
  float: left;
  padding: 10px;
}

.brand-page {
  border: none;
}

.brand-banner {
  width: 100%;
}

.brand-banner img {
  width: 100%;
}

img#brand-banner {
  height: 380px;
  object-fit: cover;
  border-radius: 0.7% 0.9% 0px 0px;
}

span.navbar-brand {
  margin-left: -8px;
}

.nav-left {
  float: left;
  width: 50%;
}

.nav-right {
  float: left;
  width: 50%;
}


.inner-main-div {
  width: 100%;
  float: left;
  margin-left: 30px;
}

.logo-left {
  float: left;
  width: 20%;
  padding: 30px;
  margin-left: 10%;
  cursor: pointer;
}

.logo-left img {
  width: 100px;
  height: 40px;
}

.menus-right {
  width: 70%;
  float: left;
}

.inner-menu:first-child {
  margin-right: -14% !important;
}

.inner-menu {
  float: right;
  padding: 40px;
  margin-top: -20px;
  text-align: center;
}

.inner-menu label {
  margin-top: 7%;
  font-family: bl-regular;
}
.inner-menu img {
  width: 30px;
}

.inner-menu:first-child {
  margin-right: 0px;
}

.ly_footer {
  padding-top: 100px;
  padding-bottom: 20px;
  background-color: #ffffff;
  float: left;
  width: 100%;
}

.hp_btGray {
  border-top: 1px solid #000 !important;
}

.ly_footer_inner {
  max-width: 1230px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #000000;
}

.ly_footer_inner small {
  color: #333333;
}

.bl_footerNav {
  display: flex;
  justify-content: space-around;
  padding: 60px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bl_footerNav_item {
  flex: 1;
  text-align: left;
}

.bl_footerNav_item > *:last-child {
  margin-bottom: 0;
}

.bl_footerNav_item:last-child .bl_footerNav_link::after {
  content: none;
}

.bl_footerNav_link {
  position: relative;
  display: block;
  margin-top: -10px;
  width: 350px;
  color: #333333;
  text-decoration: none;
  font-family: bl-bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.bl_footerNav_item button {
  width: 70%;
  float: left;
  height: 44px;
  background: #ed4d41;
  border-radius: 100px;
  border: none;
  color: white;
  margin-top: 20px;
}

.bl_footerNav_link:focus,
.bl_footerNav_link:hover {
  text-decoration: underline;
}

.bl_footerNav_link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 15px;
  background-color: currentColor;
  transform: translateY(-50%);
}

.bl_footerChildNav_item {
  margin-bottom: 10px;
}

.bl_footerChildNav_link {
  color: #000000;
  font-size: 15px;
  text-decoration: none;
}

.bl_footerChildNav_link:focus,
.bl_footerChildNav_link:hover {
  text-decoration: underline;
}

.el_footerCopyright {
  display: block;
  color: #ddd;
  font-size: 12px;
  text-align: center;
}

.article-card img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  height: 45%;
  object-fit: cover;
}

.brand-feed img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  height: 45%;
  object-fit: cover;
}

.article-card p:first-child {
  font-family: "bl-bold";
}

.carsoule-image {
  width: 100%;
  background-color: white;
  object-fit: fill;
}

.carsoule-image img {
  margin-left: 0%;
  object-fit: fill;
  width: 100%;
}

.brand-login {
  padding: 12px;
  width: 110px;
  height: 44px;
  background: #ed4d41;
  border-radius: 8px;
  border: none;
  color: #f1f1f1;
}

.brand-username {
  padding: 12px;
  width: 110px;
  height: 44px;
  border-radius: 8px;
  border: none;
  color: #333333;
  background-color: white;
}

.modalBody {
  margin-top: -3%;
}

.modal-content {
  border: none;
  padding: 20px;
  margin-top: 25%;
}

.btn-popup {
  background: linear-gradient(0deg, #ff2a68, #ff5e3a);
  height: 44px;
  color: #f1f1f1;
  border: none;
  padding: 12px;
  border-radius: 8px;
  font-family: bl-regular;
}

#for {
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
#for:hover{
  color: #cf4b00;
}

p#for:hover {
  color: #cf4b00;
}

button.user {
  text-transform: capitalize;
}

button.log {
  color: red;
}

button#following {
  background-color: transparent;
  color: #333333;
  border: solid black 1px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
}

img.card-img.second-img-inner {
  border-radius: 25px;
  width: 85px;
  height: 85px;
  box-shadow: 0px 4px 16px rgb(66 66 66 / 10%);
  margin: 10px;
  padding: 5px;
}


.logo-brand img.card-img-top {
  width: 170px;
  height: 170px;
  box-shadow: 0px 3px 12px rgb(47 47 47 / 10%);
  border-radius: 20px;
  padding: 10px;
}

.middel-div-sub {
  transform: translateY(-5.1rem);
}

.brand-btn {
  width: 10rem;
}

.brand-btn:hover{
  background-color: #ED4D41;
  border: none;
}

.follow-btn {
  background-color: white;
  border: 1px solid black;
  color: #000;
}

.msg-btn {
  background-color: #ED4D41;
  border: none;
}

.card-group #el-four {
  display: none;
}

.card-group #el-four:first-child {
  display: block;
}

.card-group #el-four:nth-child(2) {
  display: block;
}

.card-group #el-four:nth-child(3) {
  display: block;
}

.card-group #el-four:nth-child(4) {
  display: block;
}

.card-group #el-four:nth-child(5) {
  display: block;
}

.card-group #el-four:nth-child(6) {
  display: block;
}

.card-group #el-four:nth-child(7) {
  display: block;
}

img#product-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

img#other-img {
  width: 136px;
  height: 136px;
  margin: 5px;
  border-radius: 12px;
  object-fit: contain;
}

p#product_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
}

.card-group {
  display: flex;
  align-self: flex-start;
}

.show_brand {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.innier_show_brand {
  flex: 0 0 200px;
  margin: 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.see_brands {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  max-height: 100vh;
  overflow: hidden;
}

.see_brands img {
  border-radius: 22px;
  width: 100px;
  height: 100px;
  box-shadow: 0px 4px 16px rgb(66 66 66 / 10%);
  margin-bottom: 10px;
  padding: 20px;
  overflow: hidden;
}

.see_brand_card {
  width: 150px;
  height: 110px;
  border-radius: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.see_brand_card small {
  text-align: center;
  width: 100%;
  float: left;
}

.scroll-card {
  min-width: 150px;
  max-width: 300px;
}

.article-card {
  min-width: 150px;
  max-width: 300px;
}

button.brand-username.user::before {
  content: "Hi,";
  margin-right: 5px;
  font-family: "bl-regular";
}

utton.brand-username.user {
  font-family: "bl-bold";
}

.Download-More-accessbility {
  width: 100%;
  padding: 60px;
  float: left;
}

.seeall-inner {
  width: 100%;
  margin-left: 0;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  border: none;
}
.googleFont {
  margin-top: -2px;
}

.withGoogle {
  margin-top: -2%;
  border: 1px solid #bebdbd !important;
  border-radius: 8px;
  background: #ffffff;
  color: #484848;
  margin-bottom: 20px;
  font-family: bl-regular;
}

.withGoogle:hover {
  background: #ed4d41;
  color: #ffffff;
  border: none;
}
.modal-content button {
  height: 50px;
}

.customPopupBtn {
  background: linear-gradient(0deg, #ff2a68, #ff5e3a);
  font-family: bl-regular;
}

.or_option {
  margin-top: 7px;
  margin-bottom: 20px;
  font-family: bl-regular;
}

.signup_account {
  cursor: pointer;
}
footer {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  float: left;
  width: 100%;
}
.right-header-img {
  width: 100%;
  margin-top: 10px;
  max-height: 400px;
  object-fit: cover;
}

a.gmail-open {
  background-color: white;
  width: 200px;
  color: #333333;
  padding: 12px;
  border: solid #4284f4 2px;
  border-radius: 12px;
}
.g-icon {
  margin-right: 10px;
}

.reset .btn-close {
  margin-top: -10%;
}

.form-control {
  height: 50px;
  padding: 0.375rem 0.75rem;
}

@media (min-width: 750px) {
  body {
    background-color: #f0f0f0;
  }

  .first-card {
    box-shadow: 0px 4px 8px rgba(66, 66, 66, 0.1);
    margin-bottom: 2rem;
    border-radius: 15px !important;
  }

  .second-card {
    box-shadow: 0px 4px 8px rgba(66, 66, 66, 0.1);
    margin-bottom: 2rem;
    border-radius: 15px !important;
  }

  .card-group > .card {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    float: left;
    width: 150px;
  }

  .carousel-item {
    height: 30vh;
    border-radius: 15px;
  }

  .carousel-item img {
    height: inherit;
    border-radius: inherit;
  }

  .navbar {
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 960px) {
  .carousel-item {
    height: 55vh;
  }

  .carousel-item img {
    height: inherit;
  }

  .home-container {
    max-width: 80% !important;
  }

  .navbar .container {
    max-width: 80% !important;
  }


  .brand-page {
    border-radius: 15px;
    margin-bottom: 2rem;
  }

  .middel-div-sub {
    transform: none;
    justify-content: flex-start;
    flex-direction: row !important;
    margin-block: 2.5rem;
  }

  .brand-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: left;
  }

  .flex-container {
    transform: none;
    margin-left: 25px;
  }

  .logo-brand img.card-img-top {
    width: 7rem;
    height: 7rem;
    padding: 5px;
    margin-inline: 2rem;
  }

  #brand-banner {
    border-top-left-radius: inherit !important;
    border-top-right-radius: inherit !important;
  }

  .brand-banner {
    border-radius: inherit !important;
  }

  #brand-btn {
    justify-content: end !important;
    margin-right: 1rem;
  }

  .brand-btn {
    margin-inline: 1rem;
  }

  .second-brand-div {
    display: flex;
    justify-content: space-between;
  }

  .left-section {
    width: 60%;
  }

  .right-section {
    width: 37%;
  }

  .products-sec {
    flex-direction: column;
  }

  .product-div {
    flex-direction: row !important;
    margin-left: 0;
    max-width: 100%;
  }

  .product-div-right {
    text-align: start;
    margin-left: 1rem;
    padding: 0;
  }

  .right-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.btn-link {
  text-decoration: none;
}
.btn-link:hover{
  color: #ff2a68;
}
